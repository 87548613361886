import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { ProgramReadModel } from '../stores/models/program-store.types';
import { ProgramModel, ProgramFormatTrackModel, ProgramFormatModel } from '../../models/program.model';

@Injectable()
export class ProgramService {
	constructor(private http: HttpClient) {}

	async getPrograms(): Promise<ProgramReadModel[]> {
		return await lastValueFrom(this.http.get<ProgramReadModel[]>('/api/programs'));
	}

	async getAllPrograms(): Promise<ProgramModel[]> {
		return await lastValueFrom(
			this.http
				.get<ProgramReadModel[]>('/api/programs', {
					observe: 'body',
					responseType: 'json',
				})
				.pipe(
					map((response: ProgramReadModel[]) => {
						return response.map((item: ProgramReadModel) => {
							const programFormats = item.programFormats.map((pf) => {
								const programFormatTracks = pf.programFormatTracks.map((pft) => {
									return new ProgramFormatTrackModel(
										pft.programFormatTrackId,
										pft.programFormatId,
										pft.trackNumber,
										pft.trackFocus,
										pft.maxNumberOfChildTracks,
									);
								});
								return new ProgramFormatModel(
									pf.programFormatId,
									pf.programId,
									pf.formatName,
									pf.durationInMinutes,
									programFormatTracks,
								);
							});

							return new ProgramModel(
								item.programId,
								item.programName,
								item.programThemeId,
								item.programThemeName,
								programFormats,
							);
						});
					}),
				),
		);
		//return this.http.get<ProgramReadModel[]>('/api/programs');
	}
}
