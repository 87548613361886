export class ProgramModel {
	constructor(
		programId: string,
		programName: string,
		programThemeId: string,
		programThemeName: string,
		programFormats: ProgramFormatModel[],
	) {
		this.programId = programId;
		this.programName = programName;
		this.programThemeId = programThemeId;
		this.programThemeName = programThemeName;
		this.programFormats = programFormats;
	}
	private programId: string;
	private programName: string;
	private programThemeId: string;
	private programThemeName: string;
	private programFormats: ProgramFormatModel[];

	getProgramId(): string {
		return this.programId;
	}

	getProgramName(): string {
		return this.programName;
	}

	getProgramThemeId(): string {
		return this.programThemeId;
	}

	getProgramThemeName(): string {
		return this.programThemeName;
	}

	getProgramFormats(): ProgramFormatModel[] {
		return this.programFormats;
	}
}

export class ProgramFormatModel {
	constructor(
		programFormatId: string,
		programId: string,
		formatName: string,
		durationInMinutes: number,
		programFormatTracks: ProgramFormatTrackModel[],
	) {
		this.programFormatId = programFormatId;
		this.programId = programId;
		this.formatName = formatName;
		this.durationInMinutes = durationInMinutes;
		this.programFormatTracks = programFormatTracks;
	}

	private programFormatId: string;
	private programId: string;
	private formatName: string;
	private durationInMinutes: number;
	private programFormatTracks: ProgramFormatTrackModel[];

	getProgramFormatId(): string {
		return this.programFormatId;
	}

	getProgramId(): string {
		return this.programId;
	}

	getFormatName(): string {
		return this.formatName;
	}

	getDurationInMinutes(): number {
		return this.durationInMinutes;
	}

	getProgramFormatTracks(): ProgramFormatTrackModel[] {
		return this.programFormatTracks;
	}
}

export class ProgramFormatTrackModel {
	constructor(
		programFormatTrackId: string,
		programFormatId: string,
		trackNumber: number,
		trackFocus: string,
		maxNumberOfChildTracks: number,
	) {
		this.programFormatTrackId = programFormatTrackId;
		this.programFormatId = programFormatId;
		this.trackNumber = trackNumber;
		this.trackFocus = trackFocus;
		this.maxNumberOfChildTracks = maxNumberOfChildTracks;
	}

	private programFormatTrackId: string;
	private programFormatId: string;
	private trackNumber: number;
	private trackFocus: string;
	private maxNumberOfChildTracks: number;

	getProgramFormatTrackId(): string {
		return this.programFormatTrackId;
	}

	getProgramFormatId(): string {
		return this.programFormatId;
	}

	getTrackNumber(): number {
		return this.trackNumber;
	}

	getTrackFocus(): string {
		return this.trackFocus;
	}

	getMaxNumberOfChildTracks(): number {
		return this.maxNumberOfChildTracks;
	}
}
